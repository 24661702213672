document.addEventListener("turbolinks:load", function (event) {
  let zoomContainer = document.querySelector(".zoom-webinar");

  if (zoomContainer) {
    let dataset = zoomContainer.dataset;

    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareJssdk();

    // fix mobile view
    document.getElementsByTagName("body")[0].style.minWidth = "unset";
    document.documentElement.style.minWidth = "unset";
    // end

    ZoomMtg.init({
      leaveUrl: dataset.leaveUrl,
      isSupportAV: true,
      success: function() {
        ZoomMtg.join({
          signature: dataset.signature,
          apiKey: dataset.apiKey,
          meetingNumber: dataset.meetingNumber,
          userName: dataset.name,
          passWord: dataset.password,
          success: function() {
            console.log("suc");
          },
          error(res) {
            console.log(res)
          }
        })
      },
    });
  }
});
