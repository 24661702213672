let accordion = function() {
  let acc = document.getElementsByClassName("film__accordion-trigger");
  let i;

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function() {
      this.classList.toggle("film__accordion-trigger--active");
      let panel = this.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    });
  }
};

let moreInfo = function() {
  let acc = document.getElementsByClassName("film__info-trigger");
  let i;

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function() {
      this.classList.toggle("film__info-trigger--active");
      let panel = this.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    });
  }
};

window.setActiveTab = function(evt, activeId, activeClassName) {
  let i, tabContent, tabLinks;
  tabContent = document.getElementsByClassName(activeClassName + "__tab-content");
  for (i = 0; i < tabContent.length; i++) {
    tabContent[i].style.display = "none";
  }

  tabLinks = document.getElementsByClassName(activeClassName + "__tab-link");
  for (i = 0; i < tabLinks.length; i++) {
    tabLinks[i].className = tabLinks[i].className.replace(" " + activeClassName + "__tab-link--active", "");
  }

  document.getElementById(activeId).style.display = "block";
  evt.currentTarget.className += " " + activeClassName +"__tab-link--active";
};

document.addEventListener("turbolinks:load", function(event) {
  accordion.apply();
  moreInfo.apply();
  let defaultOpen = document.getElementById("defaultOpen");
  if (defaultOpen !== null) {
    defaultOpen.click();
  }
});

document.addEventListener("click", function(e) {
  if (e.target.className === "film__accordion-trigger-play") {
    e.stopPropagation();
    let time = e.target.dataset.time;
    let iframe = document.querySelector('iframe');
    let player = new Vimeo.Player(iframe);

    player.setCurrentTime(time).then(function() {
      player.play();
    })
  }
});