let isInactivityDetectorInitialized;
let time;
let totalInactiveTime;
let timeToCLickOnModal;
let playerTimeWas;
let modalIsOpen;
let active;
let events = ["load", "mousedown", "mousemove", "keypress", "scroll", "touchstart", "click"];
let modal;
let closeBtn;

function initializeObjects() {
  playerTimeWas = 0;
  modalIsOpen = false;
  active = true;
  modal = document.getElementById("inactivity_modal");
  closeBtn = document.querySelector(".inactivity-modal-close-btn");
}

let inactivityDetector = function () {
  isInactivityDetectorInitialized = true;
  clearAllTimeouts();

  events.forEach(function (name) {
    document.addEventListener(name, resetTimer, true);
  });

  setInterval(watchingVideo, 5000);
  setInterval(ping, 10000);

  function watchingVideo() {
    let iframe = document.getElementById("vimeo_iframe");

    if (iframe !== null) {
      let player = new Vimeo.Player(iframe);

      player.getCurrentTime().then(function (seconds) {
        if (seconds !== playerTimeWas) {
          playerTimeWas = seconds;
          resetTimer();
        }
      }).catch(function (error) {
        console.log("vimeoError");
      });
    }
  }

  function clearAllTimeouts() {
    let id = window.setTimeout(function () {
    }, 0);

    while (id--) {
      window.clearTimeout(id);
    }
  }

  function inactive() {
    active = false;
  }

  function redirectToLogin() {
    window.location.href = "/";
  }

  function showInactiveModal() {
    modalIsOpen = true;
    modal.style.display = "flex";

    closeBtn.onclick = function () {
      modal.style.display = "none";
      modalIsOpen = false;
      resetTimer();
    };

    timeToCLickOnModal = setTimeout(redirectToLogin, 80000);
  }

  async function ping() {
    if (active === true) {
      let url = new URL(window.location.origin + "/ping");
      let response = await fetch(url);

      if (!response.ok) {
        window.location.href = "/";
      }
    }
  }

  function resetTimer() {
    if (modalIsOpen === false) {
      clearTimeout(time);
      clearTimeout(totalInactiveTime);
      clearTimeout(timeToCLickOnModal);

      active = true;
      time = setTimeout(inactive, 10000);
      totalInactiveTime = setTimeout(showInactiveModal, 300000);
    }
  }
};

document.addEventListener("turbolinks:load", function (event) {
  if (location.pathname.substr(1) !== "") {
    initializeObjects();
    if (!isInactivityDetectorInitialized) inactivityDetector()
  }
});
